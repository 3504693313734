import './lib/common.js'; // Активация jQuery вне Webpack, создание глобальной переменной $ 
import {TimelineMax} from 'gsap'; // Анимация
import Swiper from 'swiper'; // Карусель
import fancybox from '@fancyapps/fancybox'; // Модальные окна
import maskPlugin from 'jquery-mask-plugin'; // Маска телефона
// import lazyload from 'jquery-lazyload'; // Отложенная зарузка изображений

// sayHello();
// First Animation
let tl = new TimelineMax();

tl
  .to('.loader', 0.25, {opacity: 0, onComplete: function() {$('.loader').remove();}}, 0.5);

$('.nav-burger').click(function() {
  $(this).toggleClass('is-active');
  $('.nav-wrapper').toggleClass('is-active');
  return false;
});

// Скролинг по лендингу при нажатии на ссылку
$('.scroll-to').on('click', function(e) {
  e.preventDefault();

  var id  = $(this).attr('href'),
    top = $(id).offset().top;
  $('body,html').animate({scrollTop: top}, 1500);
  $('.nav-burger').removeClass('is-active');
  $('.nav-wrapper').removeClass('is-active');
  $('body').removeClass('menu-is-active');
}); 

var swiper = () => {
  var mySwiper = new Swiper('.swiper-full', {
    init: true,
    speed: 400,
    spaceBetween: 20,
    freeMode: true,
    slidesPerView: 4,
    parallax: true,
    lazy: true,
    scrollbar: {
      el: '.swiper-scrollbar',
    },
    breakpoints: {
      300: {
        slidesPerView: 2,
        spaceBetween: 10
      },
      768: {
        slidesPerView: 3
      },
      1200: {
        slidesPerView: 4,
        spaceBetween: 20
      }
    }
  });
};

swiper();


// Маска телефона, если телефон не заполнен до конца,
// то при нажатии куда либо сбрасывает введенное в инпут
let maskPhone = '+38 000 000-00-00';
$('input[name=phone]').mask(maskPhone).focusout(function() {
  let p = $(this);
  if (p.val().length < maskPhone.length) {
    p.val('');
  }
});

// Modals
$('[data-fancybox]').fancybox({
  loop     : true,
  autoFocus: false,
  touch: {
    vertical: false
  },
  buttons: [
    'zoom',
    'close'
  ],
});



$(document).ready(function() {
  //Add Inactive Class To All Accordion Headers
  $('.accordion-header').toggleClass('inactive-header');
	
  //Set The Accordion Content Width
  // var contentwidth = $('.accordion-header').width();
  // $('.accordion-content').css({'width' : contentwidth });
	
  //Open The First Accordion Section When Page Loads
  $('.accordion-header').first().toggleClass('active-header').toggleClass('inactive-header');
  $('.accordion-content').first().slideDown().toggleClass('open-content');
	
  // The Accordion Effect
  $('.accordion-header').click(function() {
    if($(this).is('.inactive-header')) {
      $('.active-header').toggleClass('active-header').toggleClass('inactive-header').next().slideToggle().toggleClass('open-content');
      $(this).toggleClass('active-header').toggleClass('inactive-header');
      $(this).next().slideToggle().toggleClass('open-content');
    }
		
    else {
      $(this).toggleClass('active-header').toggleClass('inactive-header');
      $(this).next().slideToggle().toggleClass('open-content');
    }
  });
	
  return false;
});

// Отработка формы, проверка на пустые поля 
var params = window.location.search.replace('?','').split('&').reduce(
  function(p,e) {
    var a = e.split('=');
    p[decodeURIComponent(a[0])] = decodeURIComponent(a[1]);
    return p;
  }, {});

$('form').each(function() {
  let th = $(this).append('<div class="utm" hidden>').find('.utm');
  $.each(params, function(key, value) {
    if (key !== 0) {
      th.append('<input name="'+key+'" value="'+value+'" hidden>');
    }
  });
});

$(function() {
  $('form').each(function() {
    // Объявляем переменные (форма и кнопка отправки)
    let form = $(this),
      btn = form.find('.button-submit');

    // Добавляем каждому проверяемому полю, указание что поле пустое
    form.find('.field-input').addClass('empty-field');

    // Функция проверки полей формы
    function checkInput() {
      form.find('.field-input').each(function() {
        if($(this).val() !== '') {
          // Если поле не пустое удаляем класс-указание
          $(this).removeClass('empty-field');
        } else {
          // Если поле пустое добавляем класс-указание
          $(this).addClass('empty-field');
        }
      });
    }

    // Функция подсветки незаполненных полей
    function lightEmpty() {
      form.find('.empty-field').parent().addClass('empty-field');
      // Через полсекунды удаляем подсветку
      setTimeout(function() {
        form.find('.empty-field').parent().removeClass('empty-field');
      },1000);
    }

    // Функция отправки формы 
    // Нужно написать ajax функцию отправки формы
    function fromSubmit() {
      var phone = form.find('input[name=phone]').val();
      btn.prop('disabled', true);
      form.submit();

      $(document).on('af_complete', function(event, response) {

        var form = response.form;

        form.trigger('reset');
        btn.prop('disabled', false);
        
        $.fancybox.close();

        $.fancybox.open({
          src  : '#thanks',
          type : 'inline',
          buttons: [
            'close'
          ],
          afterLoad: function() {
            $('.check-phone').empty().append(phone);
          }
        });

        // Отслеживание лидов
        dataLayer.push({'event':'send-request'});
        // yaCounter53814523.reachGoal('lead-form-order');
        fbq('track','Lead');
      });

    }

    // Проверка в режиме реального времени
    setInterval(function() {
      // Запускаем функцию проверки полей на заполненность
      checkInput();
      // Считаем к-во незаполненных полей
      let sizeEmpty = form.find('.empty-field').length;

      // Вешаем условие-тригер на кнопку отправки формы
      if(sizeEmpty > 0) {
        if(btn.hasClass('disabled')) {
          return false;
        } else {
          btn.addClass('disabled');
        }
      } else {
        btn.removeClass('disabled');
      }
    },500);

    // Событие клика по кнопке отправить
    btn.click(function() {
      if($(this).hasClass('disabled')) {
        // подсвечиваем незаполненные поля и форму не отправляем, если есть незаполненные поля
        lightEmpty();
        return false;
      } else {
        // Все хорошо, все заполнено, отправляем форму
        fromSubmit();
      }
    });
  });
});

// Click Tracking
$('[data-click]').on('click', function() {
  let click = $(this).data('click');
  fbq('trackCustom',click);
  yaCounter54652267.reachGoal(click);
});
